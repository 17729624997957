import classNames from 'classnames'
import getConfig from 'next/config'
import Image from 'next/image'
import { memo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { When } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { HelpType, PlatformType } from 'src/types'
import styles from './index.module.scss'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

interface Props {
  animation?: boolean
  showAllPlatform?: boolean
}

function Footer(props: Props) {
  const { animation, showAllPlatform } = props
  const { $t, activeLocale: locale, i18nFormat } = useI18n()
  const [helpList] = useState<HelpType[]>(getHelpListMap())
  const [platformList] = useState<PlatformType[]>(getPlatformListMap())

  function getHelpListMap() {
    const result = $t('ResWwwEFooterGroup.items')?.slice(0, -1)
    const children = $t('ResWwwEFooterMenus.items')

    result.forEach((ele) => {
      ele.items = []
      children.forEach((item) => {
        if (item._parent === ele.key) {
          ele.items.push(item)
        }
      })
      // tracking 页面导航，因为暂时没有词条，所以在这先写死进去
      if (ele.key * 1 === 1) {
        ele.items.push(
          {
            key: '14',
            _isBlank: '1',
            _isHelpLink: '0',
            _name: $t('ResWwwHomePage.global.__footer_trckingPage'),
            _order: '05',
            _parent: '1',
            _url: '/{0}/tracking'
          },
          {
            key: '15',
            _isBlank: '1',
            _isHelpLink: '0',
            _name: $t('ResWwwHomePage.global.__footer_brands'),
            _order: '06',
            _parent: '1',
            _url: '/{0}/brands'
          }
        )
      }
    })

    return result
  }
  function getPlatformListMap() {
    const wx = $t('ResGFollow.items.itemsDict').get(21)
    const wb = $t('ResGFollow.items.itemsDict').get(22)
    const fb = $t('ResGFollow.items.itemsDict').get(11)
    const tt = $t('ResGFollow.items.itemsDict').get(12)

    const platform = [
      {
        title: wx._name,
        icon: 'fa-wechat',
        link: '',
        code: 'wx-code'
      },
      {
        title: wb._name,
        icon: 'fa-weibo',
        link: '',
        code: 'wb-code'
      },
      { title: fb._name, icon: 'fa-facebook', link: fb._url, code: '' },
      { title: tt._name, icon: 'fa-twitter', link: tt._url, code: '' },
      {
        title: 'LinkeIn',
        icon: '',
        logo: assetPrefix + '/assets/images/appdown/linke_in.svg',
        link: 'https://www.linkedin.com/company/17track',
        code: ''
      },
      {
        title: 'Youtube',
        icon: '',
        logo: assetPrefix + '/assets/images/appdown/youtube.svg',
        link: 'https://www.youtube.com/@17TRACKOFFICIAL',
        code: ''
      }
    ]

    return showAllPlatform ? platform : locale === 'zh-cn' ? platform.slice(0, 2) : platform.slice(2)
  }

  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <div className={styles.list}>
            {helpList.map((ele) => {
              return (
                <Col key={ele.key} md={3} sm={6} xs={12}>
                  <div className={styles['list-item']}>
                    <dl {...(animation ? { 'data-animation-in': 'true' } : {})}>
                      <dt className={styles.line}>{ele._name}</dt>
                      {ele.items.map((item, key) => {
                        return (
                          <dd key={key}>
                            <a
                              href={i18nFormat(item._url, [locale, 'about'])}
                              target={item._isBlank === '1' ? '_blank' : '_self'}
                            >
                              {item._name}
                            </a>
                          </dd>
                        )
                      })}
                    </dl>
                  </div>
                </Col>
              )
            })}
            <Col md={3} sm={6} xs={12}>
              <div className={styles['list-item']}>
                <dl {...(animation ? { 'data-animation-in': 'true' } : {})}>
                  <dt className="flex">
                    <Image
                      src="https://res.17track.net/global-v2/imgs/logo/svg/square_bot_128x90.svg"
                      alt="17TRACK"
                      width={32}
                      height={22}
                    />
                    {$t('ResWWWHomeFooter.global.__slogan_title')}
                  </dt>
                  <dd>{$t('ResWWWHomeFooter.global.__slogan_desc')}</dd>
                  <dd className={styles.platform}>
                    {platformList.map((ele) => {
                      if (!ele.icon) {
                        return (
                          <div
                            key={ele.link}
                            className={classNames('cursor-pointer', styles.iconImg)}
                            onClick={() => ele.link && window.open(ele.link)}
                          >
                            <img src={ele.logo} alt={ele.title} />
                          </div>
                        )
                      }
                      return (
                        <div
                          key={ele.icon}
                          className={classNames(ele.icon, 'cursor-pointer', styles[ele.icon], styles.icon)}
                          title={ele.title}
                          onClick={() => ele.link && window.open(ele.link)}
                        >
                          <When condition={ele.code}>
                            <div className={classNames(styles.popover, styles[`${ele.code}`])}></div>
                          </When>
                        </div>
                      )
                    })}
                  </dd>
                </dl>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </footer>
  )
}

export default memo(Footer)
