import { useScroll } from 'ahooks'
import classNames from 'classnames'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { When } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { PlatformType } from 'src/types'
import styles from './index.module.scss'

function Sidebar() {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()
  const [barList, setBarList] = useState<PlatformType[]>([])

  const scroll = useScroll()

  const isBackTop = useMemo(() => {
    return Number(scroll?.top) > 250
  }, [scroll])

  // 获取分享信息
  const getShareInfo = useCallback(() => {
    const defaultTitle = document.title || ''
    const defaultDesc = document.querySelector('meta[name="description"]')?.getAttribute('content') || ''
    const defaultUrl = document.querySelector('meta[itemprop="url"]')?.getAttribute('content') || ''
    const defaultImg = document.querySelector('meta[itemprop="image"]')?.getAttribute('content') || ''

    const ogTitle = document.querySelector('meta[property="og:title"]')?.getAttribute('content') || ''
    const ogDesc = document.querySelector('meta[property="og:description"]')?.getAttribute('content') || ''
    const ogUrl = document.querySelector('meta[property="og:url"]')?.getAttribute('content') || ''
    const ogImg = document.querySelector('meta[property="og:image"]')?.getAttribute('content') || ''

    const title = encodeURIComponent(ogTitle || defaultTitle)
    const description = encodeURIComponent(ogDesc || defaultDesc)
    const url = encodeURIComponent(ogUrl || defaultUrl)
    const img = encodeURIComponent(ogImg || defaultImg)

    return {
      title,
      description,
      url,
      img
    }
  }, [])

  // 获取侧边栏列表
  const getBarListMap = useCallback(() => {
    try {
      const { title, description, url, img } = getShareInfo()
      const items = $t('ResGShare.items').filter((ele) => {
        ele.icon = `fa-share-${ele.key}`
        ele._url = i18nFormat(ele._url, [url, title, description, img])
        return ele._isZh === (locale === 'zh-cn' ? '1' : '0')
      })

      return [
        { title: $t('ResGNavigation.global.__top_goTop'), icon: 'fa-publish', link: '' },
        {
          title: $t('ResGNavigation.global.__menu_contactUs'),
          icon: 'fa-comments-o',
          link: i18nFormat('//www.17track.net/{0}/about/contactus', [locale])
        },
        {
          title: $t('ResGNavigation.global.__top_share'),
          icon: 'fa-share-black',
          link: '',
          items: items.reverse()
        }
      ]
    } catch (error) {
      return []
    }
  }, [])

  // 去分享
  const toShare = (platform) => {
    window.ga?.sendSocialShare(platform)
  }

  const handleClick = (idx: number, item) => {
    switch (idx) {
      case 0:
        window?.scroll({ behavior: 'smooth', top: 0 })
        break
      case 1:
        location.href = item.link
        break
      default:
        break
    }
  }

  useEffect(() => {
    setBarList(getBarListMap())
  }, [])

  return (
    <aside className={styles['side-bar']}>
      <ul>
        {barList.map((ele, idx) => (
          <li
            key={ele.title}
            className={classNames(ele.icon, styles['sidebar-item'], { [styles.hidden]: !isBackTop && !idx })}
            title={ele.title}
            onClick={() => handleClick(idx, ele)}
          >
            <When condition={ele.items?.length}>
              <div className={styles.share}>
                {ele.items?.map((item) => (
                  <button
                    key={item.key}
                    className={classNames('cursor-pointer', item.icon, styles[item.icon], styles.link)}
                    title={item._name}
                    onClick={() => {
                      toShare(item._name)
                      window.open(item._url)
                    }}
                  >
                    <a href={item._url}></a>
                  </button>
                ))}
              </div>
            </When>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default memo(Sidebar)
