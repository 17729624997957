import { ReactNode, useEffect, useState } from 'react'
import { When } from 'react-if'
import { CarrierInfoMarkDTO } from 'src/types'
import Copyright from './copyright'
import Footer from './footer'
import styles from './index.module.scss'
import NavbarNew from './navbar_new'
import Sidebar from './sidebar'

interface Props {
  children: ReactNode
  animation?: boolean
  showAllPlatform?: boolean
  carrierInfoMark?: CarrierInfoMarkDTO
  country?: string | any
}

export default function Layout(props: Props) {
  const { animation, showAllPlatform, carrierInfoMark } = props
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])
  return (
    <>
      <a
        href="#content"
        className="visually-hidden"
        style={{
          position: 'absolute',
          height: '1px',
          width: '1px',
          overflow: 'hidden',
          clip: 'rect(1px, 1px, 1px, 1px)',
          color: 'var(--primary-color)'
        }}
      >
        Skip to main content
      </a>
      {/* 灰度展示重构头部导航 */}
      <NavbarNew carrierInfoMark={carrierInfoMark} />

      <main className="relative">
        {props.children}
        <When condition={loading}>
          <div className={styles['loading-wrapper']}>
            <div className={styles.loading}></div>
            <div className={styles['loading-text']}>Loading</div>
          </div>
        </When>
      </main>
      <Footer animation={animation} showAllPlatform={showAllPlatform} />
      <Copyright />
      <Sidebar />
    </>
  )
}
