import classNames from 'classnames'
import Link from 'next/link'
import { Fragment, memo, useContext, useRef, useState } from 'react'
import CustomImage from 'src/components/Image'
import useI18n from 'src/hooks/use_i18n'
import { IKV, NavbarDropdownMenuType, NavbarDropdownType } from 'src/types'
import { NavbarContext } from '../index'
// import useI18n from 'src/hooks/use_i18n'
import { Col, Row } from 'react-bootstrap'
import IconFont from 'src/components/IconFont'
import ArrowButton from '../arrow_button'
import { NavItemConfig } from '../types'
import styles from './index.module.scss'

interface DropdownMenuProps {
  parenStyles?: IKV
  item: NavItemConfig
  dropdownProps?: Record<string, any>[]
  activeDropdownType?: NavbarDropdownType | string | null
  setDropdownType?: (id: NavbarDropdownType | string | null) => void
}

/**
 *@Description: 处理带有drop类型的下拉菜单类型，如果由对应children则可以展现左右图片布局
 */

function DropdownMenu({ item }: DropdownMenuProps) {
  const {
    renderLabel,
    handleLazyMouseLeave,
    lazyActiveHandleDropdownFn,
    dropdownType,
    handleDropdown,
    isMoveTabOpen,
    responsiveSize,
    imgHrefConfig
  } = useContext(NavbarContext)
  const { $t } = useI18n()
  // 对应需要显示不同图片标识
  const [imgHoveredType, setImgHoveredType] = useState<string | null>(null)

  // 主要用于接受需要延迟切换菜单切换按钮的定时器引用
  const lazyTimer = useRef<number | undefined>(undefined)
  /**
   *@Description: 用于处理下拉菜单点击防止触发顶部事件
   *@param {object} e 事件对象
   *@param {String} url 跳转链接
   *@param {String} eventName ga发送名
   *@param {Object} eventAction ga发送参数
   */
  const handleNavigationClick = (e: IKV, url: string, eventName: string, eventAction: IKV) => {
    e.preventDefault()
    e.stopPropagation() // 阻止事件冒泡
    window.ga?.sendGaEvent(eventName, eventAction)
    if (e.currentTarget.target === '_blank') {
      window.open(url)
    } else {
      window.location.href = url
    }
  }

  const debounceHandleMenuImg = (type: NavbarDropdownMenuType | string, e?: IKV, gaEvent: boolean = true) => {
    if (gaEvent && (e?.type == 'mouseenter' || e?.type == 'click')) {
      // 暂时注释后续看特殊情况处理
      // e?.preventDefault()
      window.ga?.sendGaEvent(`${type}_${e?.type == 'mouseenter' ? 'popup' : e?.type}`, {
        event_category: '导航',
        event_action: e?.type == 'mouseenter' ? '曝光' : '点击'
      })
    }

    setImgHoveredType(imgHoveredType === type ? null : type)
  }

  const lazyActiveHandleMenuImgFn = (type: NavbarDropdownMenuType | string, e?: IKV, gaEvent: boolean = true) => {
    if (lazyTimer.current !== undefined) {
      clearTimeout(lazyTimer.current)
    }
    if (imgHoveredType !== type) {
      lazyTimer.current = window.setTimeout(() => {
        debounceHandleMenuImg(type, e, gaEvent)
      }, 200)
    }
  }
  return (
    <>
      <div
        key={item.key}
        className={classNames(styles['nav-item'], styles['hover-secondary'], {
          [styles['nav-item-open']]: dropdownType == item.key && !isMoveTabOpen,
          [styles['nav-item-tab-open']]: isMoveTabOpen && dropdownType == item.key
        })}
        onClick={(e) => {
          if (responsiveSize?.['isLgSize']) return
          if (item.key) {
            handleDropdown?.(item.key, e)
          }
        }}
        onMouseEnter={(e) => {
          if (!responsiveSize?.['isLgSize']) return
          if (item.key !== dropdownType) {
            lazyActiveHandleDropdownFn?.(item.key ?? null, e)
          }
        }}
        onMouseLeave={() => {
          if (!responsiveSize?.['isLgSize']) return
          handleLazyMouseLeave?.({ timerClean: true, dropdownTimerClean: true })
        }}
      >
        <div className={styles['item-toggle-label']}>{renderLabel(item.label)}</div>
        {item.dropdown && (
          <div className={styles['dropdown-menu-custom']}>
            <Row className={classNames(styles['dropdown-menu-custom-content'])}>
              <Col
                key={`flex-left-container-${item.key}`}
                className={classNames('m-0 p-0', styles['flex-left-container'])}
              >
                {item.dropdown?.map((column, index) => (
                  <div
                    key={`dropdown-${item.key}-${index}`}
                    className={classNames(styles['dropdown-item'])}
                    {...item?.dropdownProps?.[index]}
                    onMouseEnter={(e) => {
                      if (!responsiveSize?.['isLgSize']) return
                      if (column.label.key) {
                        lazyActiveHandleMenuImgFn(column.label.key, e)
                      }
                    }}
                    onMouseLeave={() => {
                      if (!responsiveSize?.['isLgSize']) return
                      if (lazyTimer.current !== undefined) {
                        clearTimeout(lazyTimer.current)
                      }
                    }}
                  >
                    {column.label?.type == 'image' ? (
                      <Fragment key={index}>{renderLabel(column.label)}</Fragment>
                    ) : (
                      <div className={styles['submenu']} key={`dropdown-submenu-${item.key}-${index}`}>
                        <ArrowButton
                          buttonKey={column.label.key}
                          buttonType="dropDownNavItem"
                          buttonText={column.label?.labelTitle ?? 'Item'}
                          buttonDesc={column.label?.labelDesc}
                          href={imgHrefConfig[column.label.key]}
                          target={column.label?.target}
                          onClick={(e) => {
                            handleNavigationClick(e, imgHrefConfig[column.label.key], `${column.label.key}_click`, {
                              event_category: '导航',
                              event_action: '点击'
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Col>
              <Col
                key={`flex-right-img-container-${item.key}`}
                className={classNames('m-0 p-0', styles['flex-right-img-container'])}
              >
                {item.dropdown?.map((column, index) => (
                  <Fragment key={`fragment-right-${item.key}-${index}`}>
                    {column.label?.children && (
                      <div
                        key={`children-${item.key}-${index}`}
                        className={classNames(styles.imgBox, {
                          [styles['img-item-open']]:
                            imgHoveredType === column.label.key || (imgHoveredType == null && index == 0)
                        })}
                        {...item?.dropdownProps?.[index]}
                      >
                        <div className={styles['item-img-box']}>
                          {column.label.children.imgUrl && (
                            <CustomImage
                              src={column.label.children.imgUrl}
                              loading="lazy"
                              decoding="async"
                              alt="17TRACK shopify landing page"
                              width={800}
                              height={300}
                              className="lazy-image-fill"
                            />
                          )}
                          <p className={classNames(styles['font-desc'])}>{column.label?.children.contentDesc}</p>
                          {column.label?.children.showMoreBtn && (
                            <Link href={imgHrefConfig[column.label.key]} passHref legacyBehavior>
                              <a
                                className={classNames(styles['font-head'], styles.arrowRight)}
                                target={column.label?.children?.target}
                                onClick={(e) => {
                                  handleNavigationClick(
                                    e,
                                    imgHrefConfig[column.label.key],
                                    `${column.label.key}_click`,
                                    {
                                      event_category: '导航',
                                      event_action: 'learnMore-点击'
                                    }
                                  )
                                }}
                              >
                                {$t('ResWwwPHome.global.__partner_moreBtn')}
                                <IconFont
                                  className={styles['arrow']}
                                  type="icon-Left_Arrow"
                                  style={{ width: '24px', height: '24px' }}
                                />
                              </a>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(DropdownMenu)
