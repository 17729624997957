import { useLockFn } from 'ahooks'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { fetchUserUnReadMsg, postUserAllMsgRead } from 'src/api/msg'
import { userMsgState } from 'src/store/msg'
import useI18n from './use_i18n'

interface Props {
  getUserInfo: () => Promise<void>
}

export default function useUserMsg({ getUserInfo }: Props) {
  const { $t } = useI18n()
  const [userMsg, setUserMsg] = useRecoilState(userMsgState)

  const getUserUnReadMsg = async () => {
    try {
      setUserMsg((pre) => ({ ...pre, status: 'loading' }))
      const res = await fetchUserUnReadMsg()

      if (res.Code === 0) {
        const { msgList = [], UnReadJCount = 0 } = res.Json
        setUserMsg({
          msgList,
          UnReadJCount,
          status: 'succeeded'
        })
      } else {
        setUserMsg((pre) => ({ ...pre, status: 'failed' }))
      }
    } catch (error) {
      setUserMsg((pre) => ({ ...pre, status: 'failed' }))
    }
  }

  const batchUserAllMsgRead = useLockFn(async () => {
    // 消息提示
    await postUserAllMsgRead()
    toast.success($t('ResGWord.global.__otherMsg_operationDone'))
    window.ga?.sendEvent?.('事件操作', '导航-功能', '17站内信', '标记为全部已读')
    getUserUnReadMsg()
    getUserInfo?.() // 更新用户是否有最新未读消息
  })

  return {
    userMsg,
    getUserUnReadMsg,
    batchUserAllMsgRead
  }
}
