import { IKV } from '../types'

/**
 * 对数据排序处理
 * @param inData {Array} 数据数组
 * @param orderField {String} 需要做排序的字段属性名称
 * @param orderAsc {Boolean} 默认是从小到大，false为从大到小
 * @returns
 */
export const dataSort = (inData: IKV[], orderField: string, orderAsc = true) => {
  let _data
  if (Object.prototype.toString.call(inData) === '[object Array]') {
    _data = inData.sort((a, b) => {
      if (orderAsc) {
        return a[orderField] > b[orderField] ? 1 : -1
      } else {
        return a[orderField] < b[orderField] ? 1 : -1
      }
    })
  } else {
    _data = inData
  }

  return _data
}
