import jsCookie from 'js-cookie'

/**
 * [setLoginDimension 设置用户角色维度]
 */
export function setLoginDimension() {
  try {
    let loginInfo = jsCookie.get('_yq_rc_'),
      guid = createGUID(),
      //创建GUID
      guidName = '_yq_bid',
      anonymityCode = jsCookie.get(guidName) //取当前cookie中的GUID

    //判断是否有设置GUID
    if (!anonymityCode?.trim()) {
      //没有设置，把创建的GUID设置到cookie中，
      //过期时间为20年，天*年，365*20 = 7300
      jsCookie.set(guidName, guid, {
        domain: '.17track.net',
        expires: 7300,
        path: '/'
      })
      anonymityCode = guid
    }

    if (loginInfo) {
      let arrLoginInfo = loginInfo.split('.')
      let userRole = arrLoginInfo[5]
      let userGid = arrLoginInfo[6]
      if (userRole && userGid) {
        setDimension(5, userRole.toString())
        setUser(userGid)
      } else {
        // 原来的cookie没有gid,统一强制删了这个cookie
        jsCookie.remove('_yq_rc_', {
          domain: '.17track.net',
          path: '/'
        })
      }
    } else {
      //未登录
      setUser(anonymityCode)
    }
  } catch (err) {
    console.log(err)
  }
}

/**
 * ga4设置用户登录角色user_id
 */
export function setUserId() {
  let _Ga4Uid = '' // 由于GA4的userId设置，并不是所有项目都需要，v3项目单独写在项目调用即可

  try {
    // 获取用户gid信息
    let loginInfo = jsCookie.get('_yq_rc_'),
      guid = createGUID(),
      //创建GUID
      guidName = '_yq_bid',
      anonymityCode = jsCookie.get(guidName) //取当前cookie中的GUID

    //判断是否有设置GUID
    if (!anonymityCode?.trim()) {
      //没有设置，把创建的GUID设置到cookie中，
      //过期时间为20年，天*年，365*20 = 7300
      jsCookie.set(guidName, guid, {
        domain: '.17track.net',
        expires: 7300,
        path: '/'
      })
      anonymityCode = guid
    }
    if (loginInfo) {
      let arrLoginInfo = loginInfo.split('.')
      let userRole = arrLoginInfo[5]
      let userGid = arrLoginInfo[6]
      if (userRole && userGid) {
        _Ga4Uid = userGid
      } else {
        // 原来的cookie没有gid,统一强制删了这个cookie
        jsCookie.remove('_yq_rc_', {
          domain: '.17track.net',
          path: '/'
        })
      }
    } else {
      //未登录
      _Ga4Uid = anonymityCode
    }
  } catch (e) {
    console.log('gid>>>', e)
  }

  if (typeof window.gtag === 'function') {
    gtag('set', 'user_id', _Ga4Uid)
  }
}

/**
 * [setUser 设置用户ID,该方法只适用动态获取userid后设置用户ID,通常在**YQG4.initPageView**后调用]
 * @{@link [https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id]}
 * @param {String} userid [用户唯一ID，比如登录邮箱]
 * @example
 * YQG4.setUser('Ryan@17track.cn')
 */
export function setUser(userid) {
  const USER_ID = userid
  if (typeof window.gtag === 'function') {
    try {
      gtag('set', 'user_id', USER_ID)
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * [setDimension 自定义维度],ga4维度暂时没处理加，ga4维度变化大，处理机制与ga3变化差异大，后续需要再补充
 * @param {init} index [0<index<21]
 * @param {[type]} value [维度的值]
 */
export function setDimension(index, value) {
  const INDEX = index
  const VALUE = value
  if (typeof window.gtag === 'function') {
    try {
      gtag('set', 'dimension' + INDEX, VALUE)
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * 创建GUID
 * @param {string} tpl 生成GUID模板，默认占位符只能是x或y，或者有数量变化，其它需求与regexp参数配合使用
 * @param {regexp} regexp 默认是匹配xy，其它需求与tpl参数配合使用
 */
export function createGUID(tpl, regexp) {
  const time = new Date().getTime()
  const result = (tpl || 'G-xxxxxxxxxxxxxxxx').replace(regexp || /[xy]/g, function (r) {
    let random = (time + Math.random() * 16) % 16 | 0
    // d = Math.floor(time / 16);
    return (r == 'x' ? random : (random & 7) | 8).toString(16).toUpperCase()
  })
  return result
}

/**
 * [hasClass 自定义hasClass方法,独立在里定义，只为独立应用]
 * @param  {[type]}  el        [父dom]
 * @param  {[type]}  className [是否包含某元素]
 * @return {Boolean}           [是否有某class]
 */
export function hasClass(el, className) {
  let hasClass

  if (el.classList) {
    hasClass = el.classList.contains(className)
  } else {
    hasClass = new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
  }
  return hasClass
}

/**
 * [lowBrowser 低版本浏览器]
 * @return {[string]} [description]
 */
export function lowBrowser() {
  let browserLevel, updateDom, htmlDom

  if (!!document.querySelectorAll && !!document.querySelector) {
    updateDom = document.querySelectorAll('.yq-brower-update')
    htmlDom = document.querySelector('html')

    if (updateDom.length < 1) {
      browserLevel = 'normal'
    } else {
      if (hasClass(htmlDom, 'csscalc')) {
        browserLevel = 'low-csscalc'
      } else if (hasClass(htmlDom, 'no-csscalc')) {
        browserLevel = 'low-no-csscalc'
      } else {
        browserLevel = 'low'
      }
    }
  } else {
    browserLevel = 'low'
  }

  return browserLevel
}

/**
 * [setMetric 自定义指标]
 * @param {init} index [0<index<21]
 * @param {[type]} value [指标的值]
 */
export function setMetric(index, value) {
  var INDEX = index
  var VALUE = value
  if (typeof window.gtag === 'function') {
    try {
      gtag('set', 'metric' + INDEX, VALUE)
    } catch (err) {
      console.log(err)
    }
  }
}
