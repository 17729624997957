export interface IKV {
  [key: string]: any
}

export interface CarrierDTO {
  country: string
  name: string
  icon: string
  link: string
}

export enum NavbarDropdownType {
  'LOGO_17TRACK' = '17_logo',
  'RESOURCES' = 'resources',
  'PRODUCT' = 'product',
  'TOOL' = 'tool',
  'HELP' = 'help',
  'LANG' = 'lang',
  'MESSAGE' = 'message',
  'USER_INFO' = 'user_info',
  'USER_REGISTER' = 'user_register',
  'CARRIERS' = 'carriers',
  'DEVELOPER' = 'developer'
}

export enum NavbarDropdownMenuType {
  'CARRIERS_ETTLEIN' = 'Carriers_CarriersEttlein',
  'CARRIERS' = 'Carriers_Carriers',
  'TRACKING_WIDGET' = 'Resources_TrackingWidget',
  'HELP_CENTER' = 'Resources_HelpCenter',
  'API_DOCUMENT' = 'Resources_ApiDocument',
  'PARTNERS' = 'Resources_Partners',
  'API' = 'Product_TrackingApi',
  'MOBILE_APP' = 'Product_MobileApp',
  'SHOPIFY_APP' = 'Product_ShopifyApp'
}

export interface HelpTypeItems {
  key: string
  _isBlank: string
  _isHelpLink: string
  _name: string
  _order: string
  _parent: string
  _url: string
}

export interface HelpType {
  key: string
  _name: string
  items: HelpTypeItems[]
}

export interface PlatformType {
  title: string
  icon: string
  code?: string
  link: string
  items?: Record<'key' | '_isZh' | '_name' | '_url' | 'icon', string>[]
  logo?: string
}

export interface CarrierInfoMarkDTO {
  ranking?: string
  partner?: string
  airwaybill?: string
  user?: string
  country?: string
  language?: string
  appInstalled?: string
  cumulativeTracking?: string
}
/**
 *@Description: carriersALL数据的每个对象类型定义
 */
export interface AllCarriersInfoDTO {
  key: number
  _country?: string
  _country_iso?: string
  _email?: string
  _tel?: string
  _url?: string
  _name?: string
  '_name_zh-cn'?: string
  '_name_zh-hk'?: string
  _group?: string
}

/**
 *@Description: 分页器点击事件参数定义
 */
export interface HandlePageEventProps {
  eventObject?: any
  page: number
}

export enum BatchTrackScene {
  'DEFAULT' = 'default',
  'BRAND' = 'brand'
}

/**
 *@Description: 定义页面使用ga4的tag判断,后续多项目引入可使用类似www-index, carriers-index...
 *@param {String} 'index' | 'track_page' | 'customers' | 'brand' | 'carriers'
 */
export type GlobalGA4TagType =
  | 'index'
  | 'track_page'
  | 'customers'
  | 'brand'
  | 'carriers-index'
  | 'tracking'
  | 'brands_index'
  | 'brands_page'
  | 'brands_info'

export interface TabItem {
  key: string
  name: string
}

export interface PlatformItem {
  alt_label: string
  cover_image: string
  external_url: string
  keywords: string
  platform: string
  subtitle: string
  title: string
  url: string
  about: string
  classify_name: string
}

export interface FaqItem {
  title: string
  uiType?: string
  content: any
}

export interface CarriersTemplate {
  head_title: string
  subtitle: string
  template_content?: string
  content?: string
  language: string
  keywords: string
  description: string
  title: string
  faq: FaqItem[]
  template?: CarriersTemplate
}

export type CmsCarrier = AllCarriersInfoDTO & CarriersTemplate
