import { updateUserLang } from '@/src/api/msg'
import { getUid, removeLanguagePrefix } from '@/src/utils/tools'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { memo } from 'react'
import useI18n from 'src/hooks/use_i18n'
import { IKV, NavbarDropdownType } from 'src/types'
import styles from './index.module.scss'

interface Props {
  dropdownType?: NavbarDropdownType | string | null
  list: IKV[]
}

function DropdownLang({ dropdownType, list = [] }: Props) {
  const { $t, activeLocale: locale } = useI18n()
  const router = useRouter()

  // 切换语言
  const handleSelLang = async (e, code: string) => {
    e?.preventDefault()
    const lang = code?.toLowerCase()
    const href = `${decodeURIComponent(
      '/' + (lang || 'en') + removeLanguagePrefix(router?.asPath, router.locales?.slice(1) || [])
    )}`

    try {
      if (getUid() && code) await updateUserLang(lang)
    } catch (error) {
      console.log(error)
    }
    location.href = href
  }

  return (
    <div
      className={classNames('custom-scroll', styles['custom-dropdown-menu'], styles['dropdown-lang'], {
        'animation-scale-up': dropdownType === NavbarDropdownType.LANG,
        [styles.open]: dropdownType === NavbarDropdownType.LANG
      })}
    >
      <div className={styles['scrollable-container']}>
        <div className={styles['list-group-menu']}>
          <a
            target="_blank"
            href={`https://help.17track.net/hc/${locale}/articles/235533167`}
            title={$t('ResGNavigation.global.__other_langEx')}
          >
            {$t('ResGNavigation.global.__other_langEx')}
          </a>
        </div>
        <div className={styles['divider']}></div>
        {list?.map((ele) => (
          <div className={styles['list-group-menu']} key={ele._code}>
            <a
              title={ele._name}
              href={`${decodeURIComponent(
                '/' + ele?._code?.toLowerCase() + removeLanguagePrefix(router.asPath, router.locales?.slice(1) || [])
              )}`}
              onClick={(e) => handleSelLang(e, ele?._code)}
            >
              <div>{ele._name}</div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(DropdownLang)
