import { useEffect } from 'react'
import { GlobalGA4TagType } from 'src/types'
import { getGlobalTag } from '../utils/tools'

// 谷歌分析
export default function useGA(type: GlobalGA4TagType) {
  useEffect(() => {
    try {
      const _ga = require('@/plugins/ga/index.js').default
      _ga.init(getGlobalTag(type), '', true) // GA 分析
      window.ga = _ga
    } catch (error) {
      console.log(error)
    }

    return () => {
      window.ga = null
    }
  }, [])

  return {}
}
