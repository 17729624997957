import { memo, useContext } from 'react'
import DropdownMenu from '../dropdown_menu'
import { NavbarContext } from '../index'
import styles from '../index.module.scss'
import { NavItemConfig } from '../types'

interface NavItemProps {
  item: NavItemConfig
}

/**
 *@Description: 生成navItem的模块方法,单独抽离方便后续扩展navItem的格式
 */
function NavItem({ item }: NavItemProps) {
  const { renderLabel } = useContext(NavbarContext)
  // 前置校验item是否有label内容，无内容默认不进行内容不渲染
  if (!item.label.content || (typeof item.label.content === 'string' && item.label.content.trim() === '')) {
    return null
  }

  return (
    <div className={styles['nav-item']} {...item.props}>
      {/* 判断是否有下拉选项子item */}
      {item.dropdown ? (
        <>{item.dropdown && <DropdownMenu key={item.key} item={item} parenStyles={styles} />}</>
      ) : (
        renderLabel(item.label)
      )}
    </div>
  )
}

export default memo(NavItem)
