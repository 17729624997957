import styles from './index.module.scss'

export default function Copyright() {
  return (
    <div className={styles.copyright}>
      © Copyright 2011-{new Date().getFullYear()} 17TRACK All Rights Reserved &nbsp;
      <a target="_blank" href="https://beian.miit.gov.cn" rel="nofollow noreferrer">
        粤ICP备11015089号
      </a>
    </div>
  )
}
