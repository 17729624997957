export const defaultContent = (platform) => {
  return [
    {
      title: `How do I track my ${platform} order?`,
      content: [
        {
          content: `You can track your ${platform} order using two simple methods:`,
          uiType: 'p'
        },
        '<strong>Using This Tracking Page:</strong>',
        {
          uiType: 'ul',
          content: [
            'Enter your tracking number in the input box on this page.',
            'Click the "Track my order" button to get real-time updates on your package status.'
          ]
        },
        {
          uiType: 'p',
          isStrong: true,
          content: 'Visiting the 17TRACK Website:'
        },
        {
          uiType: 'ul',
          content: [
            "Go to the <a href='https://www.17track.net/en/tracking' target='_blank'>17TRACK tracking</a> page.",
            'Enter your tracking number in the search bar at the top of the homepage.',
            'Click the "Track" button to view the latest information on your shipment.'
          ]
        },
        {
          uiType: 'img',
          content: {
            className: 'w-100',
            alt: "Step-by-step guide to using 17TRACK: Enter your tracking number on the 17TRACK tracking page and click 'Track' to see shipment updates.",
            path: 'common/tracking_17track'
          }
        }
      ]
    },
    {
      title: 'How to find your tracking number?',
      content: [
        'Your tracking number can usually be found in several places:',
        {
          uiType: 'ol',
          content: [
            `<strong>Order Confirmation Email:</strong> When you place an order, ${platform} sends a confirmation email that includes your tracking number.`,
            '<strong>Shipping Notification Email:</strong> Once your order has been shipped, you will receive a shipping notification email containing the tracking number.',
            `<strong>${platform} Account:</strong> Log in to your ${platform} account, go to "My Orders" and select the order you want to track. The tracking number will be listed there.`,
            '<strong>Shipping Label or Receipt:</strong> Check the shipping label or receipt you received with your package. The tracking number is usually printed there.',
            '<strong>Contact Shipping Carrier:</strong> Reach out to the customer service of the shipping carrier with your order details. They can provide you with the tracking number.'
          ]
        }
      ]
    },
    {
      title: `How to track ${platform} order without tracking number?`,
      content: [
        'If you do not have a tracking number, you can still track your order by:',
        {
          uiType: 'ol',
          content: [
            `<strong>Contacting ${platform} Customer Service:</strong> Reach out to ${platform}\'s customer service with your order details, such as order number, email address, and shipping address. They can provide you with the tracking number.`,
            `<strong>Checking Your ${platform} Account:</strong> Sometimes, the tracking number is updated in your order details in your ${platform} account even if you did not receive an email notification.`,
            `<strong>Using Order Reference Number:</strong> Some brands allow tracking with the order reference number instead of the tracking number. Check the ${platform} website or contact their support for this option.`
          ]
        }
      ]
    },
    {
      title: `Why choose 17TRACK to track your ${platform} packages?`,
      content: [
        `Choosing <a target='_blank' href='https://www.17track.net/en'>17TRACK</a> to track your ${platform} packages offers several benefits:`,
        "<strong>Comprehensive Integration:</strong> 17TRACK integrates with over 2,400 carriers, including <a target='_blank' href='https://www.17track.net/carriers/usps'>USPS</a>, <a target='_blank' href='https://www.17track.net/carriers/ups'>UPS</a>, <a target='_blank' href='https://www.17track.net/carriers/dhl-express'>DHL</a>, <a target='_blank' href='https://www.17track.net/carriers/fedex'>FedEx</a>, and many more, providing a seamless tracking experience.",
        `<strong>Real-time Updates:</strong> Get precise and timely updates about your package status with tracking accuracy up to 99.9%.`,
        '<strong>Global Reach:</strong> 17TRACK is used by over 15 million users from 220+ countries and areas.',
        '<strong>Multi-Language Support:</strong> Supports 33 languages, enhancing the user experience for global users.',
        '<strong>Batch Tracking:</strong> Track up to 40 packages simultaneously by entering multiple tracking numbers.',
        '<strong>24/7 Support:</strong> Access customer support anytime for any queries or issues.',
        `Join the millions of satisfied users who trust 17TRACK for their ${platform} tracking needs. Enter your ${platform} tracking number to access real-time tracking updates, track shipping status, and stay informed about your package’s delivery progress. Enjoy the convenience and precision of 17TRACK today, ensuring you never miss your package!`
      ]
    }
  ]
}

/**
 * platform 每页数量
 */
export const pageSize = 12

export const formatUrl = (url) => {
  if (!/^http/.test(url)) {
    return `//${url}`
  }
  return url
}
/**
 * platform 目前支持语言列表
 */

export const SUPPORT_LANGUAGE = ['en', 'de', 'es', 'fr', 'it', 'pt', 'zh-cn', 'zh-hk']

/**
 * platform blog 文章
 */
export const BLOG_SLUG = [
  'dsers',
  'cjdropshipping',
  'printful',
  'printify',
  'zendrop',
  'autods',
  'zopi',
  'spocket',
  'judgeme',
  'pagefly',
  'privy',
  'shogun',
  'releasit',
  'omnisend',
  'growave',
  'reconvert'
]
