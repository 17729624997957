import classNames from 'classnames'
import React, { createContext, CSSProperties, ReactNode, useContext, useMemo, useState } from 'react'
import styles from './index.module.scss'

interface DrawerProps {
  direction?: 'bottom' | 'top' | 'left' | 'right'
  viewHeight?: string
  children: ReactNode
  boxClassName?: string
}

interface DrawerContextType {
  openDrawer: () => void
  closeDrawer: () => void
  isOpen: boolean
}

interface DrawerComponent extends React.FC<DrawerProps> {
  Button: React.FC<{ children: ReactNode }>
  Body: React.FC<{ children: ReactNode }>
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined)

const Drawer: DrawerComponent = ({ direction = 'bottom', viewHeight = '50vh', children }: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const openDrawer = () => setIsOpen(true)
  const closeDrawer = () => setIsOpen(false)

  const { headChildren, bodyChildren } = useMemo(() => {
    const headChildren: React.ReactNode[] = []
    const bodyChildren: React.ReactNode[] = []

    React.Children.forEach(children, (child, index) => {
      if (React.isValidElement(child)) {
        if (React.isValidElement(child) && child.type === DrawerButton) {
          headChildren.push(React.cloneElement(child, { key: `DrawerButton-${index}` }))
        } else if (React.isValidElement(child) && child.type === DrawerBody) {
          bodyChildren.push(React.cloneElement(child, { key: `DrawerBody-${index}` }))
        } else {
          bodyChildren.push(React.cloneElement(child, { key: `NormalNode-${index}` }))
        }
      } else {
        // 对于非 ReactElement，直接添加到 bodyChildren
        bodyChildren.push(<React.Fragment key={index}>{child}</React.Fragment>)
      }
    })

    return { headChildren, bodyChildren }
  }, [children])

  const drawerStyle: CSSProperties = useMemo(
    () => ({
      height: direction === 'bottom' || direction === 'top' ? viewHeight : '100%',
      width: direction === 'left' || direction === 'right' ? viewHeight : '100%',
      transform: isOpen ? 'translate(0)' : getTransform(direction, viewHeight)
    }),
    [direction, viewHeight, isOpen]
  )

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer, isOpen }}>
      {/* <button onClick={openDrawer}>Open Drawer</button> */}
      {headChildren}
      <div className={classNames(styles.drawer, styles[direction], { [styles.open]: isOpen })} style={drawerStyle}>
        {bodyChildren}
      </div>
      {isOpen && <div className={styles.overlay} onClick={closeDrawer}></div>}
    </DrawerContext.Provider>
  )
}

const getTransform = (direction, height) => {
  switch (direction) {
    case 'bottom':
      return `translateY(${height})`
    case 'top':
      return `translateY(-${height})`
    case 'left':
      return `translateX(-${height})`
    case 'right':
      return `translateX(${height})`
    default:
      return ''
  }
}

const DrawerButton: React.FC<{ children: ReactNode }> = ({ children }) => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('DrawerButton must be used within a Drawer')
  }
  const { openDrawer } = context
  return <div onClick={openDrawer}>{children}</div>
}

const DrawerBody: React.FC<{ children: ReactNode }> = ({ children }) => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('DrawerBody must be used within a Drawer')
  }
  const { isOpen } = context
  return isOpen ? <div className="drawer-body">{children}</div> : null
}

Drawer.Button = DrawerButton
Drawer.Body = DrawerBody

export default Drawer
