import { IKV } from '@/src/types'
import React from 'react'

type Props = {
  className?: string
  style?: React.CSSProperties
  type: string
  onClick?: (e: IKV) => void
}

const IconFont = (props: Props) => {
  return (
    <svg
      className={`yq-icon ${props.className || ''}`}
      style={props.style}
      aria-hidden="true"
      onClick={(e) => {
        props.onClick?.(e)
      }}
    >
      <use xlinkHref={`#${props.type}`}></use>
    </svg>
  )
}

export default IconFont
