import { SUPPORT_LANGUAGE } from '@/config/platform'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useContext, useMemo } from 'react'
import { Button, Col } from 'react-bootstrap'
import { When } from 'react-if'
import { logout } from 'src/api/msg'
import { SHOPIFY_APP_LINK } from 'src/constants/customers'
import useI18n from 'src/hooks/use_i18n'
import useUserInfo from 'src/hooks/use_user_info'
import useUserMsg from 'src/hooks/use_user_msg'
import { IKV, NavbarDropdownMenuType, NavbarDropdownType } from 'src/types'
import { dataSort } from 'src/utils/nav'
import { removeUid } from 'src/utils/tools'
import IconFont from '../../../IconFont'
import DropdownCommon from '../dropdown_common'
import DropdownDrawer from '../dropdown_drawer'
import DropdownLang from '../dropdown_lang'
import DropdownUserInfo from '../dropdown_user_info'
import DropdownUserMsg from '../dropdown_user_msg'
import Hamburger from '../hamburger'
import { NavbarContext } from '../index'
import Ink from '../ink'
import NavItem from '../navItem'
import styles from './index.module.scss'

/**
 *@Description: right右布局配置项
 */

interface DropdownMenuProps {
  showHamburger?: boolean
  onMouseEnter?: () => void
}

function NavRight({ showHamburger = true, onMouseEnter }: DropdownMenuProps) {
  const router = useRouter()
  const { handleDropdown, dropdownType, handleNavigationClick, imgHrefConfig, setDropdownType, collapse, setCollapse } =
    useContext(NavbarContext)
  const { $t, activeLocale: locale } = useI18n()
  // 判断用户登录态所需
  const { userInfo, getUserInfo } = useUserInfo()
  const { userMsg, getUserUnReadMsg, batchUserAllMsgRead } = useUserMsg({ getUserInfo })
  // 语言菜单数据
  const langInfo = useMemo(() => {
    const langs = dataSort($t('ResGLanguage.items'), '_order') || []
    const current = langs.find((ele) => {
      return ele._code.toLowerCase() === locale
    })
    let supportLangs = langs
    // brands支持语言限制 SUPPORT_LANGUAGE
    if (router.pathname?.startsWith('/brands')) {
      supportLangs = langs?.filter(({ _code }) => SUPPORT_LANGUAGE.includes(_code?.toLowerCase()))
    }
    return {
      list: supportLangs,
      current
    }
  }, [locale])

  const handleLogout = async () => {
    const res = await logout()
    if (res.Code === 0) {
      removeUid()
      window.location.reload()
    }
  }
  // 登录信息角色信息不同跳转不同链接后台
  const roleLinks = {
    2: '//seller.17track.net',
    4: '//buyer.17track.net',
    16: '//api.17track.net/admin/dashboard',
    64: '//business.17track.net'
  }
  // 用于配置菜单内容
  const defaultNavConfig: IKV = [
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.LANG,
        content: (
          <div className={classNames('relative', styles.xsStatic)} data-dropdown-toggle>
            <button
              className={classNames('d-flex align-items-center gap-1', styles['btn-language'])}
              onClick={(e) => handleDropdown?.(NavbarDropdownType.LANG, e)}
            >
              <span className="line-clamp-1">{langInfo.current?._name}</span>
              <IconFont type="icon-Caret_Down" style={{ width: '20px', height: '20px' }} />
            </button>
            <DropdownLang dropdownType={dropdownType} list={langInfo?.list} />
          </div>
        )
      },
      key: NavbarDropdownType.LANG,
      props: {
        className: classNames(styles['dropdown-md-hidden'], {
          [styles['m-size-3']]: userInfo.isLogin
        })
      }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.USER_REGISTER,
        content: !userInfo.isLogin && (
          <div className={classNames('d-flex', styles['loginBtnBox'])}>
            <Button
              className={classNames('flex-1', styles.btn, styles['md-flex-show'], styles['btn-login'])}
              variant="outline-primary"
              href="//user.17track.net"
              onClick={(e) => {
                handleNavigationClick?.(e, `//user.17track.net`, `Login`, {
                  event_category: '导航',
                  event_action: '点击'
                })
              }}
            >
              {$t('ResWwwPHeadMenus.global.__signIn')}
            </Button>
            <div
              className={classNames('relative', styles['register-box'], {
                [styles.open]: dropdownType === NavbarDropdownType.USER_REGISTER
              })}
              data-dropdown-toggle
            >
              <div className={classNames('hidden', styles['md-flex-show'])}>
                <Button
                  variant="primary"
                  className={classNames(styles.btn, styles['btn-register'])}
                  onClick={(e) => handleDropdown?.(NavbarDropdownType.USER_REGISTER, e)}
                >
                  {$t('ResGlobalPHeaderMenus.global.__registerBtn')}
                </Button>
                <DropdownCommon
                  type={NavbarDropdownType.USER_REGISTER}
                  dropdownType={dropdownType}
                  list={[
                    {
                      key: NavbarDropdownMenuType.API,
                      node: (
                        <>
                          <a
                            className="justify-content-between"
                            href={imgHrefConfig[NavbarDropdownMenuType.API]}
                            target="_blank"
                            onClick={(e) => {
                              handleNavigationClick?.(
                                e,
                                imgHrefConfig[NavbarDropdownMenuType.API],
                                `Signup_developers`,
                                {
                                  event_category: '导航',
                                  event_action: '点击'
                                }
                              )
                            }}
                          >
                            <span>
                              {$t('ResWwwPHeadMenus.global.__developer')}
                              {`${['zh-cn', 'zh-hk'].includes(locale) ? '' : ' '}${$t(
                                'ResWwwPHeadMenus.global.__csignUp'
                              )}`}
                            </span>
                            <IconFont type="icon-Left_Arrow" style={{ width: '24px', height: '24px' }} />
                          </a>
                        </>
                      )
                    },
                    {
                      key: 'customer',
                      node: (
                        <>
                          <a
                            className="justify-content-between"
                            href={`https://user.17track.net/register?gb=buyer.17track.net`}
                            onClick={(e) => {
                              handleNavigationClick?.(
                                e,
                                'https://user.17track.net/register?gb=buyer.17track.net',
                                `Signup_customers`,
                                {
                                  event_category: '导航',
                                  event_action: '点击'
                                }
                              )
                            }}
                          >
                            <span>
                              {$t('ResWwwPHeadMenus.global.__customer')}
                              {`${['zh-cn', 'zh-hk'].includes(locale) ? '' : ' '}${$t(
                                'ResWwwPHeadMenus.global.__csignUp'
                              )}`}
                            </span>
                            <IconFont type="icon-Left_Arrow" style={{ width: '24px', height: '24px' }} />
                          </a>
                        </>
                      )
                    }
                  ]}
                  boxClassName={styles['bnt-left-center']}
                />
              </div>
              <div className={classNames('hidden', styles['dropdown-xs-flex-show'])}>
                <DropdownDrawer viewHeight="208px">
                  <DropdownDrawer.Button>
                    <Button variant="primary" className={classNames(styles.btn, styles['btn-register'])}>
                      {$t('ResGlobalPHeaderMenus.global.__registerBtn')}
                    </Button>
                  </DropdownDrawer.Button>
                  <DropdownDrawer.Body>
                    <div className={styles.registerDrawerBox}>
                      <div className={classNames(styles.line)}></div>
                      <a
                        className={classNames(styles['btn-register-drawerList'], styles['hover-secondary'])}
                        href={imgHrefConfig[NavbarDropdownMenuType.API]}
                        onClick={(e) => {
                          handleNavigationClick?.(
                            e,
                            imgHrefConfig[NavbarDropdownMenuType.API],
                            `${NavbarDropdownMenuType.API}_click`,
                            {
                              event_category: '导航',
                              event_action: '点击'
                            }
                          )
                        }}
                      >
                        <span>
                          {$t('ResWwwPHeadMenus.global.__developer')} {$t('ResWwwPHeadMenus.global.__csignUp')}
                        </span>
                        <IconFont type="icon-Left_Arrow" style={{ width: '24px', height: '24px' }} />
                      </a>
                      <a
                        className={classNames(styles['btn-register-drawerList'], styles['hover-secondary'])}
                        href={`https://user.17track.net/register?gb=buyer.17track.net`}
                        onClick={(e) => {
                          handleNavigationClick?.(
                            e,
                            'https://user.17track.net/register?gb=buyer.17track.net',
                            `Signup_customers`,
                            {
                              event_category: '导航',
                              event_action: '点击'
                            }
                          )
                        }}
                      >
                        <span>
                          {$t('ResWwwPHeadMenus.global.__customer')} {$t('ResWwwPHeadMenus.global.__csignUp')}
                        </span>
                        <IconFont type="icon-Left_Arrow" style={{ width: '24px', height: '24px' }} />
                      </a>
                    </div>
                  </DropdownDrawer.Body>
                </DropdownDrawer>
              </div>
            </div>
          </div>
        )
      },
      key: NavbarDropdownType.USER_REGISTER,
      props: { className: classNames('hidden-xs', styles['dropdown-mobile-flex-show'], styles['m-size-3']) }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.MESSAGE,
        content: userInfo.isLogin ? (
          <>
            <div
              className={classNames('relative', styles.xsStatic, {
                [styles.open]: dropdownType === NavbarDropdownType.MESSAGE
              })}
              data-dropdown-toggle
            >
              <button
                title={$t('ResMsg.global.__title')}
                className={classNames('cursor-pointer relative', styles.messageButton)}
                onClick={(e) => {
                  window.ga?.sendEvent?.('功能操作', '消息组件-图标点击', '', 1)
                  handleDropdown?.(NavbarDropdownType.MESSAGE, e)
                  window.ga?.sendGaEvent(`Notification`, {
                    event_category: '导航',
                    event_action: '点击'
                  })
                }}
              >
                <IconFont type="icon-Alarm" style={{ width: '20px', height: '20px' }} />

                <When condition={userMsg.UnReadJCount}>
                  <span className={styles.messageNum}>{userMsg.UnReadJCount}</span>
                </When>

                <Ink />
              </button>
              {/* 通知消息 */}
              <DropdownUserMsg
                dropdownType={dropdownType ?? null}
                userMsg={userMsg}
                batchUserAllMsgRead={batchUserAllMsgRead}
                getUserUnReadMsg={getUserUnReadMsg}
              />
            </div>
          </>
        ) : null
      },
      key: NavbarDropdownType.MESSAGE,
      props: { className: classNames(styles['dropdown-md-hidden'], styles['m-size-3']) }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.USER_INFO,
        content: userInfo.isLogin ? (
          <>
            <div
              className={classNames('relative', styles.xsStatic, styles['md-flex-show'], {
                [styles.open]: dropdownType === NavbarDropdownType.USER_INFO
              })}
              data-dropdown-toggle
            >
              {(userInfo.FUserRole == 4 || userInfo.FUserRole == 0) && (
                <>
                  {/* 非买家显示,用户信息 */}
                  <button
                    className={classNames('cursor-pointer relative', styles.userButton)}
                    onClick={(e) => handleDropdown?.(NavbarDropdownType.USER_INFO, e)}
                  >
                    <span
                      className={classNames(
                        `enum-avatar-bg-${userInfo.FPhoto || 10001}`,
                        styles.avatar,
                        styles['avatar-online']
                      )}
                    >
                      <i></i>
                    </span>
                    <Ink />
                  </button>
                  <DropdownUserInfo
                    dropdownType={dropdownType ?? null}
                    lang={locale}
                    userInfo={userInfo}
                    handleLogout={handleLogout}
                  />
                </>
              )}
            </div>
          </>
        ) : null
      },
      key: NavbarDropdownType.USER_INFO,
      props: { className: classNames(styles['dropdown-md-hidden']) }
    },
    {
      label: {
        type: 'reactNode',
        key: 'goToAccount',
        content: userInfo.isLogin ? (
          <>
            <div className={classNames('relative', styles.xsStatic, styles['md-flex-show'])} data-dropdown-toggle>
              {userInfo.FUserRole !== 4 && userInfo.FUserRole !== 0 && (
                <>
                  {/* 买家显示按钮 */}
                  <div className={classNames(styles['dropdown-md-flex-show'], styles['md-flex-show'])}>
                    <a className={classNames(styles['btn-register'], styles.btn)} href={roleLinks[userInfo.FUserRole]}>
                      {$t('ResWwwPHeadMenus.global.__goToAccount')}
                    </a>
                  </div>
                </>
              )}
            </div>
          </>
        ) : null
      },
      key: 'goToAccount',
      props: { className: classNames('hidden-xs', styles['dropdown-mobile-flex-show'], styles['m-size-3']) }
    },
    {
      label: {
        type: 'reactNode',
        key: 'Shopify_Click',
        content: (
          <Link
            className={classNames(
              'cursor-pointer relative button',
              styles['md-flex-show'],
              styles.btn,
              styles.installShopify
            )}
            prefetch={false}
            href={SHOPIFY_APP_LINK}
            onClick={(e) => {
              e.preventDefault()
              window.ga?.sendGaEvent(`Shopify_Click`, {
                event_category: '导航',
                event_action: '点击'
              })
              window.open(SHOPIFY_APP_LINK)
            }}
          >
            <span>
              <IconFont type="icon-Fullcolor" style={{ color: '#FFFFFF', width: '24px', height: '24px' }} />
            </span>
            <span>{$t('ResGENavigationMenu.items').itemsDict?.get('19')?._name}</span>
            <Ink />
          </Link>
        )
      },
      key: 'Shopify_Click',
      props: { className: classNames('hidden-xs', styles['dropdown-mobile-flex-show'], styles.installShopifyBox) }
    }
  ]

  return (
    <>
      <Col
        className={classNames(styles['navbar-right'], { [styles.expanded]: !collapse && !showHamburger })}
        xs="auto"
        md="auto"
        onMouseEnter={onMouseEnter}
      >
        {defaultNavConfig?.map((item, index) => <NavItem key={`right-${index}`} item={item} />)}
        {showHamburger && (
          <div className={classNames('visible-md', styles['hamburger_box'])}>
            <Hamburger collapse={collapse} setCollapse={setCollapse} setDropdownType={setDropdownType} />
          </div>
        )}
      </Col>
    </>
  )
}

export default memo(NavRight)
