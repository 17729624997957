import classNames from 'classnames'
import { memo, useMemo, useState } from 'react'
import { Case, Switch, When } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { UserState } from 'src/store/user'
import { NavbarDropdownType } from 'src/types'
import IconFont from '../../../IconFont'
import styles from './index.module.scss'

interface Props {
  lang: string
  dropdownType: NavbarDropdownType | string | null
  userInfo: UserState
  handleLogout: () => void
}

function DropdownUserInfo({ lang, dropdownType, userInfo, handleLogout }: Props) {
  const { $t } = useI18n()
  const [memberInfo] = useState({
    level: userInfo?.ms?.[0]?.ml,
    lable: $t('ResGBisUserLevel.items.itemsDict').get(userInfo?.ms?.[0]?.ml)?._name
  })

  const businessBtnLabel = useMemo(() => {
    switch (lang) {
      case 'zh-cn':
        return '企业版'
      case 'zh-hk':
        return '企業版'
      default:
        return 'Business'
    }
  }, [lang])

  return (
    <div
      className={classNames(styles['custom-dropdown-menu'], styles['dropdown-user-info'], {
        'animation-scale-up': dropdownType === NavbarDropdownType.USER_INFO,
        [styles.open]: dropdownType === NavbarDropdownType.USER_INFO
      })}
    >
      <div className={styles['dropdown-menu-header']}>
        <div className={classNames('flex gap-2')}>
          <div className={classNames(`enum-avatar-bg-${userInfo.FPhoto || 10001}`, styles['user-avatar'])}></div>
          <div className={classNames('flex flex-col flex-1', styles['user-info'])}>
            <div className={classNames('flex flex-col flex-1')}>
              <div className={classNames('flex justify-content-between')}>
                <div className={classNames('flex align-items-center')} title={userInfo.FNickname}>
                  {userInfo.FNickname}
                  <When condition={userInfo.FUserRole === 4}>
                    {/* 免费会员 - 显示立即购买 */}
                    <When condition={memberInfo.level === 0}>
                      <a href="//user.17track.net/baseinfo" className="flex">
                        <i className={classNames('enum-buyervip-bg-24x24-0', styles['buyer-vip'])}></i>
                      </a>
                    </When>
                    {/* 基础会员/高级会员 */}
                    <When condition={[4010, 4020].includes(memberInfo.level)}>
                      <a href="//user.17track.net/baseinfo" className="flex">
                        <i
                          className={classNames(`enum-buyervip-bg-24x24-${memberInfo.level}`, styles['buyer-vip'])}
                        ></i>
                      </a>
                      <span className={styles['vip-tag']}>{memberInfo.lable}</span>
                    </When>
                  </When>
                </div>

                <When condition={userInfo.FUserRole === 4}>
                  {/* 免费会员 - 显示立即购买 */}
                  <When condition={memberInfo.level === 0}>
                    <p className={styles['buyer-area']}>
                      <a
                        href="//buyer.17track.net/pricing"
                        title={$t('ResGWord.global.__price_buyNow') + '>>'}
                        className={styles['buyer-btn']}
                      >
                        {$t('ResGWord.global.__price_buyNow')}
                      </a>
                    </p>
                  </When>
                </When>
              </div>
              <div>
                <p className={classNames(styles.email)} title={userInfo.FEmail}>
                  {userInfo.FEmail}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex'}>
          <Switch>
            {/* 卖家 */}
            <Case condition={userInfo.FUserRole === 2}>
              <a className={styles['seller-center']} href="//seller.17track.net">
                {$t('ResSellerGBisInfo.global.__pShare_ogTitle')}
              </a>
            </Case>
            {/* 买家 */}
            <Case condition={userInfo.FUserRole === 4}>
              {/* 免费会员 - 显示立即购买 */}
              <When condition={memberInfo.level === 0}>
                <a className={styles['buyer-center']} href="//buyer.17track.net">
                  {$t('ResBuyer.global.__gShare_ogTitle')}
                </a>
              </When>
              {/* 基础会员/高级会员 */}
              <When condition={[4010, 4020].includes(memberInfo.level)}>
                <a className={styles['buyer-center']} href="//buyer.17track.net">
                  {$t('ResBuyer.global.__gShare_ogTitle')}
                </a>
              </When>
            </Case>
            {/* api */}
            <Case condition={userInfo.FUserRole === 16}>
              <a className={styles['seller-center']} href="//api.17track.net/admin/dashboard">
                Tracking API
              </a>
            </Case>
            {/* 企业版 */}
            <Case condition={userInfo.FUserRole === 64}>
              <a className={styles['seller-center']} href="//business.17track.net">
                {businessBtnLabel}
              </a>
            </Case>
          </Switch>
        </div>
      </div>

      <div className={styles['dropdown-menu-footer']}>
        <a href="//user.17track.net/baseinfo" className={styles.account}>
          {/* <i className={classNames('fa-user')}></i> */}
          <IconFont type="icon-Account" style={{ width: '24px', height: '24px', color: '#1E1E1E' }} />
          {$t('ResGNavigation.global.__user_user')}
        </a>
        <a className={classNames('cursor-pointer', styles.exit)} onClick={handleLogout}>
          {$t('ResGNavigation.global.__user_quit')}
        </a>
      </div>
    </div>
  )
}

export default memo(DropdownUserInfo)
