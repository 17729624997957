import classNames from 'classnames'
import { memo } from 'react'
import IconFont from 'src/components/IconFont'
import styles from './index.module.scss'

interface ArrowButtonProps {
  buttonType: string
  buttonText: string
  buttonDesc?: string
  isMove?: boolean
  buttonKey: string
  href?: string
  target?: string
  onClick?: (e: any) => void
}

function ArrowButton({
  buttonType,
  buttonText,
  buttonDesc,
  isMove,
  buttonKey,
  onClick,
  href,
  target
}: ArrowButtonProps) {
  const getTransform = () => {
    switch (buttonType) {
      case 'dropDownNav':
        return (
          <div key={buttonKey} className="d-flex justify-content-between align-items-center">
            <p className="m-0">{buttonText}</p>
            <IconFont
              type="icon-Caret_Down"
              className={classNames(styles.arrowDown, {
                [styles.arrowDownRotate]: isMove
              })}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        )
      case 'dropDownNavItem':
        return (
          <a
            key={buttonKey}
            className={classNames('head cursor-pointer d-inline-block', styles.arrowRightBox)}
            target={target}
            href={href}
            onClick={onClick}
          >
            <div>
              <p className={classNames(styles.arrowRight, styles['font-head'])}>
                {buttonText}
                <IconFont
                  className={styles['arrow']}
                  type="icon-Left_Arrow"
                  style={{ width: '24px', height: '24px' }}
                />
              </p>
              {buttonDesc && <p className={classNames(styles['font-desc'])}>{buttonDesc}</p>}
            </div>
          </a>
        )
      default:
        return <></>
    }
  }

  return getTransform()
}

export default memo(ArrowButton)
