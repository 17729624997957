import Head from 'next/head'
import useI18n from 'src/hooks/use_i18n'

interface MetaSEOProps {
  title?: string
  description?: string
  keywords?: string[] | string
  socialMeta?: boolean
}

/**
 *@Description: 留意使用时title、desc自己传最好，默认是seo运营商给的一版内容，部分词条是有歧义的，默认使用组件时留意是否可以使用默认
 */
function MetaSEO({ title, description, keywords = [], socialMeta = true }: MetaSEOProps) {
  const { $t } = useI18n()

  // 暂时先不用该数据
  // const _newKeywords = [
  //   $t('ResWwwPSeo.global.__seoKeyWordText1'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText2'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText3'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText4'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText5'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText6'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText7'),
  //   $t('ResWwwPSeo.global.__seoKeyWordText8')
  // ]

  // 没有自定义用新词条
  // keywords = keywords || _newKeywords
  // ResWwwPSeo涉及到的这几块title、desc啥的内容是之前seo运营商给的一版内容，部分词条是有歧义的，默认使用组件时留意是否可以使用
  const seoTitle = title || $t('ResWwwPSeo.global.__seoTitleText') + ' | 17TRACK'
  const seoDesc = description || $t('ResWwwPSeo.global.__seoDescriptionText')
  let seoKeyword: string = $t('ResWWWAndM.global.__seoKeyword')
  if (keywords) {
    if (Array.isArray(keywords) && keywords.length) {
      // 如果是数组，使用 join 方法将其转换为字符串
      seoKeyword = keywords.join(', ')
    } else if (typeof keywords === 'string' && keywords.length) {
      // 如果是字符串，直接使用
      seoKeyword = keywords
    }
  }
  return (
    <Head>
      <title key="title">{seoTitle}</title>
      <meta name="description" content={seoDesc} key="description" />
      <meta name="keywords" content={seoKeyword} key="keywords" />
      <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      <meta name="google-site-verification" content="gAPsLnQUE4cXDoLe6SOWVT_yZPMwabppAt54KfDRvcw" />
      {socialMeta && (
        <>
          <meta property="og:title" content={seoTitle} key="ogTitle" />
          <meta property="og:description" content={seoDesc} key="ogDescription" />
          <meta property="og:url" content="https://www.17track.net" key="ogUrl" />
          <meta
            property="og:image"
            content="https://res.17track.net/global-v2/imgs/oauth_image/share.png"
            key="ogImage"
          />
          <meta itemProp="name" content={seoTitle} key="itemPName" />
          <meta itemProp="description" content={seoDesc} key="itemPDescription" />
          <meta itemProp="url" content="https://www.17track.net" key="itemPUrl" />
          <meta
            itemProp="image"
            content="https://res.17track.net/global-v2/imgs/oauth_image/share.png"
            key="itemPImage"
          />
          <meta name="twitter:card" content="summary_large_image" key="twitterCard" />
          <meta name="twitter:site" content="@17TRACK" key="twitterSite" />
          <meta name="twitter:title" content={seoTitle} key="twitterTittle" />
          <meta name="twitter:description" content={seoDesc} key="twitterDescription" />
        </>
      )}
    </Head>
  )
}

export default MetaSEO
