import { atom } from 'recoil'
import { KEYS } from './keys'

export interface UserState {
  [key: string]: any
  ms: Record<'ml' | 'mt', number>[]
  status: 'loading' | 'succeeded' | 'failed'
  isLogin: boolean
  hasNewMsg: boolean
  FUserRole: number
}

export const userInfoState = atom<UserState>({
  key: KEYS.USER_INFO,
  default: {
    ms: [{ ml: 0, mt: 0 }], // 会员信息 ml => 0 免费买家 4010 => 基础会员 4020 => 高级会员
    status: 'loading',
    isLogin: false,
    hasNewMsg: false,
    FUserRole: 0 // 2 => 卖家 4=> 买家 16 => api
  }
})
