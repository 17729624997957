import { NavbarDropdownType } from '@/src/types'
import classNames from 'classnames'
import { Dispatch, SetStateAction, memo } from 'react'
import styles from './index.module.scss'

interface Props {
  collapse?: boolean
  setCollapse?: Dispatch<SetStateAction<boolean>>
  setDropdownType?: Dispatch<SetStateAction<NavbarDropdownType | string | null>>
}

function Hamburger({ collapse, setCollapse, setDropdownType }: Props) {
  const handleToggle = () => {
    setCollapse?.((pre: boolean) => !pre)
    setDropdownType?.(null)
  }

  return (
    <button
      type="button"
      className={classNames(styles.hamburger, { [styles['hamburger-close']]: !collapse })}
      onClick={handleToggle}
      data-dropdown-toggle
    >
      <div className={classNames(styles['nav-icon'], { [styles['open']]: !collapse })}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  )
}

export default memo(Hamburger)
