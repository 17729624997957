import { useRecoilState } from 'recoil'
import { fetchUserInfo } from 'src/api/msg'
import { userInfoState } from 'src/store/user'
import { getLocalLang, getUid, setLang } from '../utils/tools'

export default function useUserInfo() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const getUserInfo = async () => {
    if (!getUid()) return
    try {
      const res = await fetchUserInfo()

      if (res.Code === 0 && res.Json?.UserInfo?.gid) {
        const Json = res.Json || {}

        setUserInfo({
          ...userInfo,
          ...Json.UserInfo,
          status: 'succeeded',
          memberInfo: Json.UserInfo.ms,
          isLogin: true,
          hasNewMsg: Json.hasNewMsg
        })

        // 判断已登录用户语言和本地语言是否一致(不一致以用户语言为主)
        const userLang = Json.UserInfo?.FLanguage
        const { v5_Culture } = getLocalLang(window.location.href)

        if (userLang && userLang !== v5_Culture) {
          const url = new URL(window.location.href)
          // 类似过滤掉第一个/en/carriers 这个/en/的参数，由于不确定en会是什么内容，用正则直接匹配过滤掉
          const urlPathName = url.pathname?.replace(/^\/[^/]+/, '')
          setLang(userLang)
          window.location.replace(`${url.origin}/${userLang}${urlPathName}${window.location.search}`)
        }
      } else {
        setUserInfo((pre) => ({ ...pre, status: 'succeeded' }))
      }
    } catch (error) {
      setUserInfo((pre) => ({ ...pre, status: 'failed' }))
    }
  }

  // useEffect(() => {
  //   if (!userInfo.status) {
  //     console.log('userInfo.status', userInfo.status)
  //     setUserInfo((pre) => ({ ...pre, status: 'loading' }))
  //     getUid() && getUserInfo()
  //   }
  // }, [userInfo.status])

  return {
    userInfo,
    getUserInfo
  }
}
