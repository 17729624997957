import Image, { ImageProps } from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import { supportsWebP } from 'src/utils/tools'

let supportsWebPInfo = {
  has: true,
  finish: false
}

interface CustomImageProps extends ImageProps {
  isWebp?: boolean // 是否需要使用webp
  defaultType?: string // 指定图片默认格式
  errorSrc?: string // 指定异常图片src路径
  errorSrcType?: string // 指定异常图片使用的图片格式
}

/**
 *@Description: 支持webp使用改为可选项，默认后缀格式可自定义，默认使用png
 *@param {props} 正常传递image原生属性即可
 *@param {props: boolean} isWebp 额外拓展了是否支持webp
 *@param {props: string} defaultType 额外拓展了指定默认格式图片后缀，用于后续可扩展内容
 *@param {props: string} errorSrc 额外拓展了指定无图片时，兜底的图片src
 *@param {props: string} errorSrcType 额外拓展了指定无图片时，兜底的图片src图片后缀
 */
function CustomImage(props: CustomImageProps) {
  const { src, isWebp = true, defaultType = 'png', errorSrc, errorSrcType = 'png', ...resetProps } = props
  const [errorUrl, setsErrorUrl] = useState<string>('' as string)
  const getSrc = useMemo(() => {
    if (errorUrl) {
      return `${errorUrl}.${errorSrcType}`
    } else if (supportsWebPInfo?.has && isWebp) {
      return `${src}.webp`
    } else {
      return `${src}.${defaultType}`
    }
  }, [errorUrl, errorSrcType, supportsWebPInfo, isWebp, src, defaultType])

  useEffect(() => {
    if (!supportsWebPInfo.finish) {
      supportsWebPInfo = {
        has: supportsWebP(),
        finish: true
      }
    }
  }, [])

  return (
    <Image
      {...resetProps}
      src={getSrc}
      onError={() => {
        if (errorSrc) setsErrorUrl(errorSrc)
      }}
    />
  )
}

export default React.memo(CustomImage)
