import http from '../lib/axios'

const url = 'https://msg.17track.net/msgapi/call'

const userUrl = 'https://user.17track.net/userapi/call'

const baseParms = {
  version: '1.0',
  sourcetype: 0,
  timeZoneOffset: -480
}

// 获取用户信息
export const fetchUserInfo = async () => http.post(url, { ...baseParms, method: 'GetLoginStatus' })

// 退出登录
export const logout = async () =>
  http.post(userUrl, {
    ...baseParms,
    method: 'Signout'
  })

// 获取用户未读消息
export const fetchUserUnReadMsg = async () =>
  http.post(url, {
    ...baseParms,
    method: 'GetMsgListAndCount',
    param: {
      createTime: 0,
      count: 10,
      state: 0
    }
  })

// 标记全部消息为已读
export const postUserAllMsgRead = async () =>
  http.post(url, {
    ...baseParms,
    method: 'SetUnreadToRead'
  })

// 切换语言
export const updateUserLang = async (lang: string) =>
  http.post(userUrl, {
    ...baseParms,
    method: 'SetLanguage',
    param: { Language: lang }
  })
