import classNames from 'classnames'
import { memo } from 'react'
import { IKV, NavbarDropdownType } from 'src/types'
import styles from './index.module.scss'

interface Props {
  type: NavbarDropdownType | string | null
  dropdownType?: NavbarDropdownType | string | null
  list: IKV[]
  boxClassName?: string
}

function DropdownCommonBlock({ type, dropdownType, list = [], boxClassName }: Props) {
  return (
    <div
      className={classNames('custom-scroll', boxClassName, styles['custom-dropdown-menu'], {
        [styles.open]: dropdownType === type
      })}
    >
      {list?.map((ele, index) => (
        <div className={styles['list-group-menu']} key={ele._key || index}>
          {ele.node ? (
            ele.node
          ) : (
            <a title={ele._name} href={ele._href} {...ele.props}>
              <div>{ele._name}</div>
            </a>
          )}
          {ele._needDivider && <div className={styles['divider']}></div>}
        </div>
      ))}
    </div>
  )
}

export default memo(DropdownCommonBlock)
