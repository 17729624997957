import { atom } from 'recoil'
import { KEYS } from './keys'

interface MsgDTO {
  createTime: string
  id: string
  state: number // 0 - 未读消息 1 - 已读消息
  title: string
}

export interface MsgState {
  msgList: MsgDTO[]
  UnReadJCount: number
  status: 'loading' | 'succeeded' | 'failed'
}

export const userMsgState = atom<MsgState>({
  key: KEYS.USER_MSG,
  default: {
    msgList: [],
    UnReadJCount: 0,
    status: 'loading'
  }
})
